import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default function useCategoriesList() {
  const toast = useToast();
  const refCategoriesTable = ref(null);

  const perPageOptions = [10, 25, 50, 100];
  const perPage = ref(10);
  const searchQuery = ref("");
  const totalCategories = ref(0);
  const currentPage = ref(1);
  const categoryType = ref("");
  const isAddNewSidebarVisible = ref(false);
  const isLoading = ref(false);

  const categoryTypes = [
    { label: "Company", value: "company" },
    { label: "Freelancer", value: "freelancer" },
    { label: "Investor", value: "investor" },
    { label: "Advisor", value: "advisor" },
    { label: "Idea", value: "idea" },
    { label: "Project", value: "project" },
    { label: "Product", value: "product" },
    { label: "Service", value: "service" },
  ];

  const categoryStatuses = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const changeDisplayOrder = async (id, event) => {
    try {
      const formData = new FormData();
      formData.append("displayOrder", event);
      const response = await axios.put(`categories/updateOrder/${id}`, formData);
      refCategoriesTable.value.refresh();
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: "Unable to update Display Order , please try again later ",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const fetchCategories = async () => {
    try {
      isLoading.value = true;
      const formData = new FormData();
      formData.append("q", searchQuery.value);
      formData.append("page", currentPage.value);
      formData.append("perPage", perPage.value);
      formData.append("type", categoryType.value ? categoryType.value.value : "");

      const response = await axios.post("categories/search", formData);
      const { data } = response;
      totalCategories.value = data.count;
      return data.data;
    } catch (error) {
      if (error.response.status === 401) {
        toast({
          component: ToastificationContent,
          props: {
            title: "you dont have enough permisssion to make this action.",
            icon: "AlertTrainingIcon",
            variant: "danger",
          },
        });
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title:
              typeof error.response !== "undefined"
                ? error.response.data.error.message
                : error.message || "Unable to fetch the categories, please try again later.",
            icon: "AlertTrainingIcon",
            variant: "danger",
          },
        });
      }
    } finally {
      isLoading.value = false;
    }
  };

  const addCategory = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("arabicName", data.arabicName);
      formData.append("slug", data.slug);
      formData.append("type", data.type);
      formData.append("isActive", data.isActive);
      formData.append("displayOrder", data.displayOrder);
      const response = await axios.post("categories", formData);

      toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });

      return response;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new category",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  const tableColumns = [
    { key: "name", sortable: false },
    { key: "arabicName", sortable: false, label: "Arabic name" },
    { key: "slug", sortable: false },
    { key: "type", sortable: false },
    { key: "displayOrder", sortable: false },
    { key: "isActive", sortable: false, label: "Status" },
    { key: "actions" },
  ];

  const resolveCategoryStatusVariant = (status) => {
    if (status == 1) return "success";
    return "danger";
  };

  const dataMeta = computed(() => {
    const localItemsCount = refCategoriesTable.value
      ? refCategoriesTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCategories.value,
    };
  });

  const refetchData = () => {
    refCategoriesTable.value.refresh();
  };

  // let's watch if there is something changed in the category
  watch([perPage, searchQuery, categoryType, currentPage], () => {
    refetchData();
  });

  return {
    perPage,
    perPageOptions,
    searchQuery,
    fetchCategories,
    tableColumns,
    resolveCategoryStatusVariant,
    refCategoriesTable,
    totalCategories,
    currentPage,
    dataMeta,
    categoryTypes,
    categoryType,
    changeDisplayOrder,
    isAddNewSidebarVisible,
    categoryStatuses,
    addCategory,
    refetchData,
    isLoading,
  };
}
